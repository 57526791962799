import React, { useState } from 'react'
import {
  Layout,
  Footer,
  Section,
  Input,
  Row,
  Column,
  Button,
  Container,
  TitleSubsection,
  Text,
  Map,
  SEO
} from '../components'
import styled from 'styled-components'
import { Formik, Form, Field } from 'formik'
import * as Yup from 'yup'
import { useTranslation } from 'react-i18next'
import { styles } from '../Theme'
import { Link } from 'gatsby'
import axios from 'axios'

const Contact = () => {
  const [sendMessage, setSendMessage] = useState(false)
  const [errorSendMessage, setErrorSendMessage] = useState(false)
  const { t } = useTranslation()

  const initialValues = {
    name: '',
    city: '',
    email: '',
    subject: '',
    message: ''
  }

  Yup.setLocale({
    mixed: {
      required: t('contact:validation-text')
    }
  })

  const ValidationSchema = Yup.object().shape({
    name: Yup.string().required(),
    city: Yup.string().required(),
    email: Yup.string()
      .email(t('contact:validation-email'))
      .required(),
    subject: Yup.string().required(),
    message: Yup.string().required()
  })

  const handleSubmit = async values => {
    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/v1/contact-us`, values)
      setSendMessage(true)
    } catch (error) {
      setErrorSendMessage(true)
    }
  }

  return (
    <Layout>
      <SEO title={t('titlePages:contact')} />
      {!sendMessage && !errorSendMessage ? (
        <>
          <Wrapper>
            <Row justifyContent='center'>
              <ContainerText>
                <Section title='contact:title' text='contact:main-text' textAlign='center' />
              </ContainerText>
            </Row>
            <Container>
              <Formik initialValues={initialValues} validationSchema={ValidationSchema} onSubmit={handleSubmit}>
                <Form>
                  <Row flexWrap='wrap' mb={4}>
                    <Column width={[1, 1, 1 / 2]} mb={4}>
                      <Field
                        name='name'
                        type='text'
                        label={t('contact:name-input')}
                        placeholder={t('contact:name-placeholder')}
                        component={Input}
                      />
                    </Column>
                    <Column width={[1, 1, 1 / 2]} mb={4}>
                      <Field
                        name='city'
                        type='text'
                        label={t('contact:city-input')}
                        placeholder={t('contact:city-placeholder')}
                        component={Input}
                      />
                    </Column>
                    <Column width={[1, 1, 1 / 2]} mb={4}>
                      <Field
                        name='email'
                        type='text'
                        label={t('contact:email-input')}
                        placeholder={t('contact:email-placeholder')}
                        component={Input}
                      />
                    </Column>
                    <Column width={[1, 1, 1 / 2]} mb={4}>
                      <Field
                        name='subject'
                        type='text'
                        label={t('contact:subject-input')}
                        placeholder={t('contact:subject-placeholder')}
                        component={Input}
                      />
                    </Column>
                    <Column width={[1]} mb={4}>
                      <Field
                        name='message'
                        type='text'
                        label={t('contact:message-input')}
                        placeholder={t('contact:message-placeholder')}
                        multiline
                        component={Input}
                      />
                    </Column>
                  </Row>
                  <Row>
                    <ContainerButton>
                      <StyledButton>{t('contact:button-text')}</StyledButton>
                    </ContainerButton>
                  </Row>
                </Form>
              </Formik>
              <Row justifyContent='center'>
                <ContainerContact mb={4}>
                  <StyledTitle color={styles.colors.red}>{t('contact:title2')}</StyledTitle>
                  {t('contact:main-text2', { returnObjects: true }).map(line => (
                    <Text key={line}>{line}</Text>
                  ))}
                </ContainerContact>
              </Row>
            </Container>
          </Wrapper>
          <Map />
        </>
      ) : (
        <StyledContainer>
          <Message color={styles.colors.red}>
            {errorSendMessage ? t('contact:error-feedback-message-title') : t('contact:feedback-message-title')}
          </Message>
          <Link to='/'>
            <StyledButtonFeedback>{t('contact:feedback-message-button')}</StyledButtonFeedback>
          </Link>
        </StyledContainer>
      )}
      <Footer />
    </Layout>
  )
}

export default Contact

const StyledTitle = styled(TitleSubsection)`
  margin-bottom: 24px;
`
const ContainerContact = styled(Column)`
  align-items: center;
`
const StyledButton = styled(Button)`
  min-width: 180px;
  margin-bottom: 40px;
`
const ContainerText = styled(Column)`
  max-width: 850px;
`
const ContainerButton = styled(Column)`
  width: 100%;
  align-items: center;
  @media (min-width: 768px) {
    align-items: flex-end;
  }
`
const Wrapper = styled.div`
  margin: 0;
  @media (min-width: 992px) {
    margin: 0 174px;
  }
`
const StyledButtonFeedback = styled(Button)`
  width: 196px;
`
const Message = styled(TitleSubsection)`
  margin-bottom: 32px;
`
const StyledContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-height: 442px;
`
